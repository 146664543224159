import { api } from '../utils/api';
/** 新增收货地址  */
export async function addAddress(data) {
  return api('/web/ec/receipt/add', data, 'POST', true);
}
/** 修改收货地址  */
export async function updateAddress(data) {
  return api('/web/ec/receipt/update', data, 'POST', true);
}

/** 获取收货地址信息  */
export async function getAddressInfo(data) {
  return api('/web/ec/receipt/detail', data, 'POST', true);
}
/** 删除收货地址  */
export async function deleteAddress(data) {
  return api('/web/ec/receipt/delete', data, 'POST', true);
}
/** 设置默认收货地址  */
export async function setDefaultAddress(data) {
  return api('/web/ec/receipt/default', data, 'POST', true, true, true);
}
// 获取收货地址列表
export async function getAddressList(data) {
  return api('/web/ec/receipt/list', data, 'POST', true);
}

/** 获取默认收货地址信息  */
export async function getDefaultReceipt(data) {
  return api('/web/ec/receipt/default/get', data, 'POST', true);
}
