import { api } from '../utils/api';
/** 获取产品列表  */
export async function getProductList(data) {
    return api('/web/ec/spu/list', data, 'POST', false, false);
}

/** 获取产品详情  */
export async function getProductDetail(data) {
    return api('/web/ec/spu/detail', data, 'POST', false, false, true);
}

/** 获取产品sku  */
export async function getSkuList(data) {
    return api('/web/ec/sku/list', data, 'POST', false, false, false);
}

/** 获取库存信息  */
export async function getStockinfo(data) {
    return api('/web/ec/stock/info', data, 'POST', false, false, false);
}

/** 获取库存列表  */
export async function getStockList(data) {
    return api('/web/ec/stock/list', data, 'POST', false, false, false);
}
