import { api } from '../utils/api';
/** 获取厂商列表  ??*/
export async function getStoreList(data) {
    return api('/web/ec/store/list', data, 'POST', false);
}

/** 获取厂商详情 ?? */
export async function getStoreDetail(data) {
    return api('/web/ec/store/detail', data, 'POST', false, false, false);
}
