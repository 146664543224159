import { api } from '../utils/api';

/** 获取订单 统计  */
export async function getOrderCount(data) {
    return api('/web/ec/order/group/count', data, 'POST', true, true, false);
}

/** 创建订单  */
export async function getOrderAdd(data) {
    return api('/web/ec/order/add', data, 'POST', true);
}

/** 创建兑换订单  */
export async function getOrderExchange(data) {
    return api('/web/ec/order/exchange', data, 'POST', true);
}

/** 订单列表  */
export async function getOrderList(data) {
    return api('/web/ec/order/list', data, 'POST', true);
}

/** 订单详情页  */
export async function getOrderDetail(data) {
    return api('/web/ec/order/detail', data, 'POST', true);
}
/** 订单 取消  */
export async function cancelOrder(data) {
    return api('/web/ec/order/cancel', data, 'POST', true, false, true);
}
/** 订单 删除  */
export async function deleteOrder(data) {
    return api('/web/ec/order/delete', data, 'POST', true, false, true);
}
/** 订单支付 继续支付 */
export async function orderPayment(data) {
    return api('/web/ec/order/pay', data, 'POST', true);
}

/** 订单完成 确认收货 */
export async function orderComplete(data) {
    return api('/web/ec/order/complete', data, 'POST', true);
}

/** 添加到购物车 */
export async function orderCartUpdate(data) {
    return api('/web/ec/order/cart/update', data, 'POST', true, true, true);
}

/** 购物车列表 */
export async function orderCartList(data) {
    return api('/web/ec/order/cart/list', data, 'POST', true, false, true);
    // return api('/web/ec/order/cart/list', data, 'POST');
}

/** 移除购物车 */
export async function orderCartDelete(data) {
    return api('/web/ec/order/cart/delete', data, 'POST', true, false, true);
}

/** 批量移除购物车 */
export async function orderCartRemove(data) {
    return api('/web/ec/order/cart/remove', data, 'POST', true, false, true);
}
/** 订单售后详情  */
export async function getOrderAfterSalesDetail(data) {
    return api('/web/ec/order/service/info', data, 'POST', true);
}
/** 订单申请退款  */
export async function requestRefund(data) {
    return api('/web/ec/order/apply', data, 'POST', true);
}
/** 订单申请售后  */
export async function applyAfterSalesService(data) {
    return api('/web/ec/order/service/add', data, 'POST', true);
}
/** 订单撤销申请  */
export async function revokeApplyAfterSalesService(data) {
    return api('/web/ec/order/service/status', data, 'POST', true);
}

/** 订单条目信息，传item_id  */
export async function getOrderItemInfo(data) {
    return api('/web/ec/order/item/info', data, 'POST', true);
}

/** 订单售后列表 */
export async function getOrderServiceList(data) {
    return api('/web/ec/order/service/list', data, 'POST', true);
}
/** 订单售后列表 */
export async function deleteServiceOrder(data) {
    return api('/web/ec/order/service/delete', data, 'POST', true);
}


/** 获取订单退款信息 */
export async function getRefundInfo(data) {
    return api('/web/ec/order/refund/info', data, 'POST', true);
}
