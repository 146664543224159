import { api } from '../utils/api';
// 获取卡券信息
export async function getCouponInfo(data) {
    return api('/web/ec/coupon/detail', data, 'POST', false, false, false);
}
// 卡券统计
export async function getCouponCount(data) {
    return api('/web/ec/coupon/group', data, 'POST', true, true, false);
}
// 用户的卡券列表
export async function getCouponList(data) {
    return api('/web/ec/coupon/user', data, 'POST', true);
}
// 用户的卡券详情
export async function userCouponInfo(data) {
    return api('/web/ec/coupon/info', data, 'POST', true);
}

// 获取任务的其他奖励  卡券列表
export async function getCouponAll(data) {
    return api('/web/ec/coupon/all', data, 'POST', false);
}